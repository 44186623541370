import * as Sentry from '@sentry/vue'
import { createHead } from '@unhead/vue'
import { createApp, h } from 'vue'
import Vue3Lottie from 'vue3-lottie'
import { version } from '../package.json'
import App from './App.vue'
import { SENTRY_DSN, getEnv } from './config/index'
import router from './router'
import posthogPlugin from './services/posthog/posthog'
import './styles/main.css'

const app = createApp({
  setup() {},

  render: () => h(App),
})

Sentry.init({
  app,
  release: version,
  enabled: getEnv() !== 'dev' && getEnv() !== 'local',
  environment: getEnv(),
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  sampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  sendDefaultPii: true,
  trackComponents: true,
  hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
})

app.use(posthogPlugin)
app.use(router)
app.use(Vue3Lottie, { name: 'LottieAnimation' })

const head = createHead()
app.use(head)

const isClient = true
const initialState = {}

const ctx = { app, router, isClient, initialState }

interface ModuleInterface {
  install: (ctx: unknown) => void
}

const modules: Record<string, ModuleInterface> = import.meta.glob('./modules/*.ts', { eager: true })
Object.values(modules).forEach(m => {
  m.install?.(ctx)
})

app.config.globalProperties.window = window

app.mount('#app')

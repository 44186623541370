export interface User {
  // api Props
  auth: UserAuth
  auth_provider_id: string
  client_id: string
  id: string
  name: string
  email: string
  disabled: boolean
  roles: string[]
  created_at: string
  updated_at: string

  // local Props

  first_name?: string
  last_name?: string
}

export interface UserAuth {
  can: {
    delete: boolean
    see: {
      boms: boolean
      catalog: boolean
      knowledge: boolean
      projects: boolean
      reports: boolean
      settings: boolean
      suppliers: boolean
    }
    update: boolean
  }
}

// Not adding "user" since this role doesn't allow anything
export enum UserRoles {
  ADMIN = 'admin',
  PROJECT_MANAGER = 'project_manager',
  CATALOG_MANAGER = 'catalog_manager',
  SOURCING_MANAGER = 'sourcing_manager',
  USER = 'user',
}
